.SkillsComponent {
  margin-bottom: 8vw;
  scroll-margin-top: 14vw;
}

.Skills {
  margin-bottom: 3.6vw;
  text-align: left;
}

p {
  margin-bottom: 1.25vw;
}

.SkillsContainer {
  position: relative;
  height: 7.1vw;
  display: flex;
  margin-bottom: 48px;
  margin-right: 5.7vw;
  justify-content: left;
}

.SkillsContainer:hover {
  cursor: url('../../assets/cursor.cur');
}

.Skill::before {
  content: attr(data-line);
}

.Icon {
  width: 85%;
  height: auto;
  vertical-align: middle;
  margin: 0 auto;
}

.Arrow {
  transform: rotate(145deg) scaleY(-1);
  width: 14vw;
  height: auto;
  position: relative;
  left: 35vw;
}

@media all and (max-width: 414px) {
  .Skills {
    margin-bottom: -10vw;
  }

  p {
    margin-bottom: 4.2vw;
    margin-top: 20.6vw;
  }

  .SkillsContainer {
    display: grid;
    grid-template-columns: repeat(3, 20.8vw);
    grid-column-gap: 7.5vw;
    grid-row-gap: 16.5vw;
    height: auto;
  }

  .Arrow {
    transform: rotate(145deg) scaleY(-1);
    width: 30vw;
    left: 28vw;
    margin-top: 16.4vw;
  }
}
.MobileMenu {
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 10.25vw;
  z-index: 999999999;
}

.MobileMenuOpen {
  left: 0;
  transition: left 1s ease-out;
}

.MobileMenuClosed {
  left: 100vw;
  transition: left 1s ease-out;
}

.MobileMenu[mode='light'] {
    background: #C9D9C2;
  }

.MobileMenu[mode='dark'] {
  background:#1B494B;
}

.MenuList {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 22vw;
}

.Link {
  font-family: 'DM Sans', sans-serif;
  font-size: 13.8vw;
  line-height: 1.8;
}

.Link[mode='light'] {
  color: #1B494B;
}

.Link[mode='dark'] {
  color:#C9D9C2;
}
.About {
  margin-bottom: 5vw;
  scroll-margin-top: 14vw;
}

.AboutContainer {
  display: flex;
  justify-content: space-between;
  padding-right: 4vw;
}

h2 {
  flex: 5;
}

p {
  width: 45vw;
  max-width: 100%;
  height: auto;
  flex: 6;
}

.Arrow {
  transform: rotate(200deg) scaleY(-1);
  width: 14vw;
  height: auto;
  position: relative;
  left: 26vw;
}

@media all and (max-width: 414px) {
  .AboutContainer {
    display: block;
  }

  .Note {
    width: 90vw;
    line-height: 1.3;
  }

  .Arrow {
    transform: rotate(183deg) scaleY(-1);
    width: 30vw;
  }
}
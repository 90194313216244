.SkillContainer {
  background-color: white;
  border-radius: 12px;
  height: 100%;
  width: 7.1vw;
  margin-right: 2.2vw;
  display: flex;
  align-items: center;
  justify-items: center;
  position: relative;
  bottom: 0;
  transition: bottom 0.2s;
}

.SkillContainer:hover {
  bottom: 20px;
  cursor: url('../../../assets/cursor.svg'), pointer;
}

.SkillContainer::before {
  content: attr(data-line);
  position: absolute;
  font-family: 'DM Sans', sans-serif;
  font-size: 1.4vw;
  width: 100%;
  color: white;
  top: 6vw;
  text-align: center;
  transform: translateY(50%);
  opacity: 0;
}

.SkillContainer:hover::before {
  opacity: 1;
  transform: translateY(100%);
  animation: SKILL-REVEAL 0.8s;
}

@keyframes SKILL-REVEAL {
  0% { opacity: 0; transform: translateY(40%) }
  10% { opacity: 0.6; transform: translateY(70%) }
  20% { opacity: 0.7; transform: translateY(80%) }
  50% { opacity: 0.8; transform: translateY(120%) }
  90% { opacity: 0.9; transform: translateY(90%) }
  100% { opacity: 1; transform: translateY(100%) }
}

@media all and (max-width: 1024px) {
  .SkillContainer::before {
    opacity: 1;
    top: 6.5vw;
  }

  .SkillContainer:hover::before {
  opacity: 1;
  transform: translateY(50%);
  animation: none;
  }

  .SkillContainer:hover {
    bottom: 0;
  }
}

@media all and (max-width: 414px) {
  .SkillContainer {
    width: 20.8vw;
    height: 20.8vw;
  }

  .SkillContainer::before {
    font-size: 5vw;
    top: 20vw
  }
}
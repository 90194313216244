.Contact {
  background-color: #1b494b;
  width: 113.3%;
  position: relative;
  right: 5.7vw;
  border-radius: 7px 7px 0 0;
}

.Title {
  width: 18.2vw;
  padding-top: 12.4vw;
  margin: 0 auto 3.4vw auto;
  color: white;
}

.ContactLinks {
  padding-bottom: 9vw;
}

.Email,
.Github,
.Linkedin,
.CV {
  display: flex;
  width: auto;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.Email a:hover,
.Github a:hover,
.Linkedin a:hover,
.CV a:hover {
  text-decoration: underline;
}

.Email h4 {
  margin-right: 4vw;
}

.Github h4,
.Linkedin h4,
.CV h4 {
  margin-right: 2vw;
}

.Email {
  color: white;
}

.Github a,
.CV a {
  color: #c4dec1;
}

.GithubArrow[mode='light'],
.CVArrow[mode='light'] {
  filter: brightness(0) saturate(100%) invert(95%) sepia(6%) saturate(900%)
    hue-rotate(61deg) brightness(93%) contrast(89%);
}

.GithubArrow[mode='dark'],
.CVArrow[mode='dark'] {
  filter: brightness(0) saturate(100%) invert(23%) sepia(17%) saturate(1502%)
    hue-rotate(131deg) brightness(98%) contrast(90%);
}

.Linkedin {
  color: white;
}

.Email,
.Github,
.Linkedin,
.CV {
  overflow-y: hidden;
  height: 7.5vw;
  margin-bottom: 3.09vw;
}

.Invisible {
  display: inline-block;
  transition: transform 0.4s ease-out;
  transform: translateY(7vw);
}

.Visible {
  display: inline-block;
  transition: transform 0.4s ease-out;
  transform: translateY(0);
}

.Design {
  position: relative;
  top: 7vw;
  width: 30vw;
  margin: 0 auto;
}

.DesignerLink:hover {
  text-decoration: underline;
}

@media all and (max-width: 414px) {
  .Contact {
    height: 100vh;
  }

  .Title {
    width: 18.2vw;
    padding-top: 40.4vw;
    margin: 0 auto 13.6vw auto;
    color: #c9d9c2;
    position: relative;
    right: 12vw;
  }

  .ContactLinks {
    padding-bottom: 9vw;
    color: white;
  }

  .Email,
  .Github,
  .Linkedin,
  .CV {
    margin-bottom: 12vw;
  }

  .Email a,
  .Github a,
  .Linkedin a,
  .CV a {
    color: white !important;
    font-size: 6.8vw;
    line-height: 2.2;
  }

  .EmailArrow[mode='light'],
  .GithubArrow[mode='light'],
  .LinkedinArrow[mode='light'],
  .CVArrow[mode='light'],
  .EmailArrow[mode='dark'],
  .GithubArrow[mode='dark'],
  .LinkedinArrow[mode='dark'],
  .CVArrow[mode='dark'] {
    filter: none;
    width: 4.5vw;
    position: relative;
    bottom: 0.2vw;
  }

  .Design {
    top: 22vw;
    width: 52vw;
    font-size: 3.5vw;
    margin: 0 auto;
  }
}
